import('@root/launchApp')
  .then(launchModule => {
    console.debug('launchModule loaded')
    const launch = launchModule.default
    launch()
  })
  .catch(err => {
    console.error('Error loading launchModule', err)
  })
  .finally(() => {
    console.debug('After launch')
  })

console.debug(`Loading ${config.name}`)
